import React, { useState, useEffect, useContext } from 'react';
import StepperContext from '../StepperContext';
import {
  Container,
  Button,
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadIcon from '@mui/icons-material/Download';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { getResults } from '../utils/api';
import RadarChart from '../../../RadarChart/RadarChart';
import Tabs from '../Partials/Tabs/Tabs';
import CardList from '../Partials/CardList/CardList';
import ResultCard from '../Partials/ResultCard/ResultCard';
import stepResultsJson from './StepResults.json';
import PdfDownloader from '../../../PdfDownloader/PdfDownloader';
import {
  getRadarChartData,
  getResultsByType,
  getTopFiveData,
} from './StepResults.helper';
import mockResultsFinal from '../example.results-final.json';

const StepResults = ({ headline, text, results, button, finalResult }) => {
  const { currentIndex, setStepIndex, user, debug, mockData } =
    useContext(StepperContext);

  const isFinalResult = finalResult || currentIndex === 'resultsFinal';

  const [loading, setLoading] = useState(true);
  const [chartIndex, setChartIndex] = useState(0);
  const [radarChartData, setRadarChartData] = useState([]);
  const [tabData, setTabData] = useState();
  const [results_, setResults] = useState();
  const [pdfProps, setPdfProps] = useState();

  const initResults = results => {
    const newResultsObject = {
      intermediate: getResultsByType(results, 'scale_intermediate'),
      final: getResultsByType(results, 'scale_final'),
      education: getResultsByType(results, 'education'),
    };
    const chartScales =
      newResultsObject.final?.scales || newResultsObject.intermediate?.scales;
    let chartData = getRadarChartData(chartScales);

    // Rename labels as quickfix
    chartData.labels.map((label, index) => {
      if (label === 'Entrepreneur') {
        chartData.labels[index] = 'Unternehmertum';
      }

      if (label === 'Naturwissenschaften') {
        chartData.labels[index] = 'Naturwissenschaft';
      }
    });

    setResults({ ...newResultsObject });
    setPdfProps({
      results: newResultsObject,
      descriptions: stepResultsJson,
      uid: user,
      chart: chartData,
    });

    let highestChartIndex = 0;
    let highestChartData = chartData.data[0];

    chartData.data.map((data, index) => {
      if (data > highestChartData) {
        highestChartIndex = index;
        highestChartData = data;
      }
    });

    setChartIndex(highestChartIndex);
    setRadarChartData(chartData);
    setLoading(false);
  };

  useEffect(() => {
    if (user && !mockData) {
      getResults(user)
        .then(res => {
          debug && console.log('res: ', res);
          if (res.results) {
            initResults(res.results);
          }
        })
        .catch(e => {
          debug && console.error(e.message);
        });
    } else if (results) {
      initResults(results);
    } else if (mockData) {
      initResults(mockResultsFinal.results);
    }
  }, []);

  useEffect(() => {
    // Prepare data for tabs (education results)
    const educationData = results_?.education;
    if (educationData) {
      let tempTabData = [];

      let topFiveItems = [];

      getTopFiveData(educationData, results_.education.desired_result).map((resultItem, index) => {
        topFiveItems.push(
          <ResultCard
            kicker={resultItem.type}
            title={resultItem.title}
            progress={resultItem.percentage}
            url={resultItem.url}
          />,
        );
      });

      const topFiveTabData = {
        id: 'top-five',
        label: 'Top 5',
        content: <CardList items={[...topFiveItems]} />,
      };

      tempTabData.push(topFiveTabData);

      Object.entries(results_.education).map(key => {
        const eduKey = key[0];
        const eduData = key[1];

        let tempTabItem = {
          id: eduKey,
        };

        switch (eduKey) {
          case 'apprenticeships': {
            let items = [];

            // Show it only if requested by the user
            if ( Array.from(['beides','ausbildungsberufe']).includes(results_.education.desired_result) ) {
              eduData.map((resultItem, index) => {
                // index < 5 &&
                items.push(
                  <ResultCard
                    kicker="Ausbildung"
                    title={resultItem.title}
                    progress={resultItem.percentage}
                    url={resultItem.url}
                  />,
                );
              });

              tempTabItem = {
                ...tempTabItem,
                label: 'Ausbildung',
                content: <CardList items={[...items]}/>,
              };

              tempTabData.push(tempTabItem);
            }
            break;
          }
          case 'courses': {
            let items = [];

            // Show it only if requested by the user
            if ( Array.from(['beides','studienfaecher']).includes(results_.education.desired_result) ) {
              eduData.map((resultItem, index) => {
                // index < 5 &&
                items.push(
                  <ResultCard
                    kicker="Studium"
                    title={resultItem.title}
                    progress={resultItem.percentage}
                    url={resultItem.url}
                  />,
                );
              });

              tempTabItem = {
                ...tempTabItem,
                label: 'Studium',
                content: <CardList items={[...items]}/>,
              };

              tempTabData.push(tempTabItem);
            }
            break;
          }
        }
      });
      setTabData(tempTabData);
    }
  }, [results_]);

  if (loading) {
    return '';
  }

  return (
    <Container maxWidth="sm">
      {headline && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, x: 20, scale: 1 }}
            animate={{ opacity: 1, x: 0, scale: 1, transition: { delay: 0 } }}
            exit={{ opacity: 0, x: -20, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            key={currentIndex}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="center"
              gutterBottom
            >
              {headline}
            </Typography>
          </motion.div>
        </AnimatePresence>
      )}

      {text && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: 1,
              scale: [0, 1.05, 0.95, 1],
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.4, delay: 0.05 }}
            key={currentIndex}
          >
            <Typography
              // variant="h6"
              component="div"
              // fontWeight={200}
              textAlign="center"
              sx={{ mb: 4 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </motion.div>
        </AnimatePresence>
      )}

      {/* Radar Chart */}
      {radarChartData && (
        <Grid container>
          {/* Prev button */}
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="large"
              // disabled={index === 0}
              aria-label="Vorheriges Ergebnis"
              onClick={() =>
                chartIndex === 0
                  ? setChartIndex(radarChartData.data.length - 1)
                  : setChartIndex(chartIndex - 1)
              }
            >
              <ChevronLeftIcon />
            </IconButton>
          </Grid>

          {/* Radar chart */}
          <Grid item xs={10} id="radarchart-canvas">
            <RadarChart
              chartData={radarChartData.data}
              show={true}
              labels={radarChartData.labels}
              activeIndex={chartIndex}
              animations={Boolean(false)}
              // tension={0.5}
            />
          </Grid>

          {/* Next button */}
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="large"
              // disabled={index === radarChartData.data.length - 1}
              aria-label="Nächstes Ergebnis"
              onClick={() =>
                chartIndex === radarChartData.data.length - 1
                  ? setChartIndex(0)
                  : setChartIndex(chartIndex + 1)
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>

          {/* Description for radar chart */}
          <Grid item xs={12}>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0, x: 20, scale: 1 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  transition: { delay: 0 },
                }}
                exit={{
                  opacity: 0,
                  x: -20,
                  scale: 1,
                  transition: { duration: 0.1 },
                }}
                // transition={{ duration: 0.3, delay: 0.4 }}
                key={chartIndex}
              >
                <Typography textAlign="center" fontWeight="bold" gutterBottom>
                  {radarChartData.labels[chartIndex]}:{' '}
                  {radarChartData.data[chartIndex]}%
                </Typography>
                <Typography textAlign="center">
                  {stepResultsJson[radarChartData.labels[chartIndex]]}
                </Typography>
              </motion.div>
            </AnimatePresence>
          </Grid>
        </Grid>
      )}

      {isFinalResult && tabData && (
        <Box sx={{ pt: 4 }}>
          <Tabs items={tabData} />
        </Box>
      )}

      <Grid
        container
        spacing={2}
        sx={{
          position: 'sticky',
          bottom: { xs: 10, sm: 20 },
          zIndex: 10,
          mt: 8,
          display: 'block !important'
        }}
      >
        {/* Download PDF button */}
        {isFinalResult && results_?.final && (
          <Grid item md={12} sx={{ pt: '0 !important', pb: 2 }}>
            <PdfDownloader
              {...pdfProps}
              button={
                <Button
                  sx={{ backgroundColor: '#fff' }}
                  endIcon={<DownloadIcon />}
                  fullWidth
                  props={button}
                  variant="outlined"
                >
                  {'PDF Download'}
                </Button>
              }
            />
          </Grid>
        )}

        {/* Next button */}
        <Grid item sx={{ flexGrow: 1, pt: '0 !important' }}>
          <Button
            // sx={{ mt: 4, position: 'sticky', bottom: { xs: 10, sm: 20 } }}
            fullWidth
            props={button}
            variant="contained"
            onClick={() => {
              button.redirect && setStepIndex(button.redirect);
            }}
          >
            {button.label || 'Los Gehts'}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepResults;
