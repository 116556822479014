import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import Stepper from './Stepper/Stepper';
import { DialogContext } from './Stepper/StepperContext';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useMatomo from './Stepper/utils/tracking';
import { logoutUser } from './Stepper/utils/api';
import { setUserUrlParameter } from './Stepper/utils/helper';

const Karrierenavigator = props => {
  const { trackEvent } = useMatomo();
  const theme = useTheme();
  const desktopMode = useMediaQuery(theme.breakpoints.up('lg'));
  const dialogButton = Boolean(props.dialogButton);
  const [screenKey, setScreenKey] = useState();
  const [user, setUser] = useState();
  const [open, setOpen] = useState(Boolean(props.dialogOpen));
  const [stepperProps, setStepperProps] = useState();

  // Open dialog
  const handleOpen = () => {
    setStepperProps({ ...props });
    setOpen(true);
  };
  // Close dialog
  const handleClose = () => {
    let props = { ...stepperProps };

    if (
      user &&
      screenKey &&
      screenKey !== 'userCode' &&
      screenKey !== 'start'
    ) {

      props.stepIndex = 'userCode';
      setStepperProps({ ...props });
    } else {
      setOpen(false);

      logoutUser()
        .then(res => { console.log('Logged out current user'); })
        .catch(e => { console.warn(e.message); });

      setUserUrlParameter('');
    }

    trackEvent(null, null, 'Close');
  };

  const toggleButton = document.querySelectorAll('.karrierenavigator');

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    const paramUid = currentUrlParams.get('uid');

    paramUid && setOpen(true);

    toggleButton.forEach(button => {
      button.addEventListener('click', () => {
        handleOpen();
      });
    });

    open && handleOpen();
  }, []);

  return props.dialog ? (
    <>
      <DialogContext.Provider
        value={{
          user: user,
          setUser: setUser,
          screenKey: screenKey,
          setScreenKey: setScreenKey,
        }}
      >
        {dialogButton && (
          <button className="button button--primary" onClick={handleOpen}>
            Karrierenavigator
          </button>
        )}

        <Dialog
          onClose={handleClose}
          open={open}
          scroll={'body'}
          // maxWidth={'xl'}
          // fullWidth={true}
          fullScreen={true}
        >
          <Stepper
            {...stepperProps}
            stepIndex={props.stepIndex}
            withDialogHeader={true}
            handleClose={user => handleClose(user)}
            desktopMode={desktopMode}
          />
        </Dialog>
      </DialogContext.Provider>
    </>
  ) : (
    <Stepper
      {...stepperProps}
      stepIndex={props.stepIndex}
      desktopMode={desktopMode}
    />
  );
};

export default Karrierenavigator;
